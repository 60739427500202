import DocumentMeta from 'react-document-meta';
import { Link } from "react-router-dom"

export default function Error() {
  const meta = {
    title: '找不到頁面',
    description: '找不到頁面',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: ''
      }
    }
  };
  return (
    <DocumentMeta {...meta}>
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="uppercase font-bold text-4xl tracking-wide mb-5 md:text-6xl ">
        Error 404 | Page Not Found
        找不到頁面
      </h1>
      <Link
        to="/"
        className="py-2 px-6 rounded shadow text-white bg-black hover:bg-transparent border-2 border-black transition-all duration-500 hover:text-black font-bold"
      >
        回到主頁
        Back to homepage
      </Link>
    </div>
    </DocumentMeta>
  )
}
