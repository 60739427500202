import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useRef } from 'react'
import CardPlacehoderSkeleton from "../components/CardPlacehoderSkeleton"
import BreadcrumbsWithIcon from "../components/BreadcrumbsWithIcon"

export default function ProductList() {
  const meta = {
    title: 'tipsluxury.com',
    description: 'tipsluxury.com',
    canonical: 'https://www.tipsluxury.com/',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: ''
      }
    }
  };



  return (
    <DocumentMeta {...meta}>
      <div className='container flex flex-col min-h-screen mx-auto'>
        <section className='mt-4 ml-2 md:ml-0'>
          <BreadcrumbsWithIcon />
        </section>
        <section className='mx-auto grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 '>
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
          <CardPlacehoderSkeleton />
        </section>



      </div>
    </DocumentMeta>
  )
}
