import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from "./components/Header"
import Footer from "./components/Footer"
import Error from "./pages/error"
import ProductList from "./pages/product-list"
import Index from "./pages/index"

import "./index.css";

function App() {

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
